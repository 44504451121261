import { Vue, Component } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import ApiClientFactory from '@/api/apiClientFactory';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { otherServiceListDispatcher } from '@/views/otherService/otherServiceList/store/other-service-list.module';
import {
  CreateOtherServiceInput,
  EditOtherServiceInput,
  OtherServiceListViewModel
} from '@/api/api';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption
  }
})
export default class OtherServiceFormModal extends Vue {
  otherService: OtherServiceListViewModel | null = null;
  input = this.getDefaultInputValue();
  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  openCreateModal(): void {
    this.otherService = null;
    this.input = this.getDefaultInputValue();
    this.$refs.modal.show();
  }

  openEditModal(otherServiceListViewModel: OtherServiceListViewModel): void {
    this.otherService = otherServiceListViewModel;
    this.input = { name: otherServiceListViewModel.name as string };
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: ''
    };
  }

  add(): void {
    const client = new ApiClientFactory().otherServiceClient();

    client.create(this.input as CreateOtherServiceInput).then(() => {
      otherServiceListDispatcher.load();
      this.$refs.modal.hide();
      // show toast
      this.$bvToast.toast('Thêm mới dịch vụ khác thành công', {
        title: 'Dịch vụ khác',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  edit(): void {
    const client = new ApiClientFactory().otherServiceClient();

    client
      .edit(
        this.otherService?.id as number,
        this.input as EditOtherServiceInput
      )
      .then(() => {
        otherServiceListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa dịch vụ khác thành công', {
          title: 'Dịch vụ khác',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.otherService) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
